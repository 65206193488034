import { render, staticRenderFns } from "./assessment-detail.vue?vue&type=template&id=f36eabd4"
import script from "./assessment-detail.vue?vue&type=script&lang=js"
export * from "./assessment-detail.vue?vue&type=script&lang=js"
import style0 from "./assessment-detail.vue?vue&type=style&index=0&id=f36eabd4&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports